import React from 'react';

const Footer: React.FC = () => (
  <footer className="flex justify-center">
    <div className="flex max-w-[960px] flex-1 flex-col">
      {/* Nueva sección con las 3 secciones horizontales */}
      <div className="flex flex-col md:flex-row justify-around px-5 py-10 text-center gap-10 md:gap-0 border-t border-gray-300">
        {/* Sección Legal */}
        <div className="flex flex-col items-center md:items-start">
          <h3 className="text-[#0e141b] text-lg font-bold mb-2">Legal</h3>
          <p className="text-[#4e7397] text-sm leading-normal">Registro MINVU: N° 1090</p>
          <img src="/Minvu.png" alt="Logo MINVU" className="h-32 mt-2" />
        </div>

        {/* Sección Dirección */}
        <div className="flex flex-col items-center md:items-start">
          <h3 className="text-[#0e141b] text-lg font-bold mb-2">Dirección</h3>
          <p className="text-[#4e7397] text-sm leading-normal">A. Varas 920 Oficina 602,</p>
          <p className="text-[#4e7397] text-sm leading-normal">Temuco, Chile</p>
        </div>

        {/* Sección Mapa */}
        <div className="flex justify-center md:justify-end">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3147.855183457239!2d-72.59901848437887!3d-38.7374744795959!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9614d60d9786db8b%3A0x5c7b2f9b0f72d091!2sA.%20Varas%20920%2C%20Temuco%2C%20Araucan%C3%ADa%2C%20Chile!5e0!3m2!1ses!2ses!4v1696000471080!5m2!1ses!2ses"
            width="300"
            height="200"
            style={{ border: 0 }}
            allowFullScreen={true}
            loading="lazy"
          ></iframe>
        </div>

        {/* Sección Teléfono */}
        <div className="flex flex-col items-center md:items-start">
          <h3 className="text-[#0e141b] text-lg font-bold mb-2">Contacto</h3>
          <p className="text-[#4e7397] text-sm leading-normal">Tel: 45 2 520 121</p>
          <p className="text-[#4e7397] text-sm leading-normal">Cel: +56 9 9443 3687</p>
          <p className="text-[#4e7397] text-sm leading-normal">gerencia@cgelevadores.cl</p>
        </div>

      </div>

      <div className="flex flex-col gap-6 px-5 py-10 text-center">
        <div className="flex flex-wrap items-center justify-center gap-6">
          <a className="text-[#4e7397] text-base font-normal leading-normal min-w-40" href="#inicio">Inicio</a>
          <a className="text-[#4e7397] text-base font-normal leading-normal min-w-40" href="#productos">Productos</a>
          <a className="text-[#4e7397] text-base font-normal leading-normal min-w-40" href="#servicios">Servicios</a>
          <a className="text-[#4e7397] text-base font-normal leading-normal min-w-40" href="#proyectos">Proyectos</a>
          <a className="text-[#4e7397] text-base font-normal leading-normal min-w-40" href="#contacto">Contacto</a>
        </div>
        <p className="text-[#4e7397] text-base font-normal leading-normal">@2024 CG Elevadores SPA</p>
      </div>
    </div>
  </footer>
);

export default Footer;
